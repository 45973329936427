
import {
    GET_CLIENT_ADVISOR


} from "../actions/types";

const initialState = {

    clinetsData : null,
    advisorData : null,
    queryData : null,
    stepsCompletedData : null,
    stepsInprogressData : null,
    momentData : null,
    futureData : null,
    stabilityData : null,
    opportunityData : null
}




const dashboardDataReducer = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case GET_CLIENT_ADVISOR:
          state = {...state, clinetsData:payload?.clients, advisorData :payload?.advisors, queryData : payload?.queries , stepsCompletedData : payload?.stepsCompletedUser, stepsInprogressData : payload?.stepsProgressingUser, momentData : payload?.momentCount, opportunityData : payload?.opportunityCount, stabilityData : payload?.stabilityCount, futureData : payload?.futureCount }
          return state
            
            break;
    
        default:
            return state
            break;
    }


}


export default dashboardDataReducer;
