import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect, lazy } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearMessage } from "../../../actions/message";

import ProtectedRoutes from "../../../routes/ProtectedRoutes";
import PublicRoutes from "../../../routes/PublicRoutes";

const Login = lazy(() => import("../../Login/"));
const Dashboard = lazy(() => import("../../Dashboard/"));
const ForgotPassword = lazy(() => import("../../ForgotPassword/"));
const ResetPassword = lazy(() => import("../../ResetPassword/"));
const AdminList = lazy(() => import("../../AdminAdvisors/List"));
const Admin = lazy(() => import("../../AdminAdvisors/Admin"));
const UserList = lazy(() => import("../../Users/All"));
const User = lazy(() => import("../../Users/User"));
const AddUsers = lazy(() => import("../../AddUser/AddUser"));
const CSRList = lazy(() => import("../../AddUser/List"));
const Profile = lazy(() => import("../../Profile"));
const AddUser = lazy(() => import("../../Users/AddUser"));
const Query = lazy(()=> import("../../CustomerQuery/index"))
const SettingsConfig = lazy(()=> import ("../../Settings/Config"));
const SettingsGeneral = lazy(()=> import ("../../Settings/General"));
const QueryView = lazy(()=> import ("../../CustomerQuery/query") );
const HomeCms = lazy (() => import ("../../Settings/HomeCms") );
const AboutCms = lazy (() => import ("../../Settings/AboutCms") );
const HowItWorks = lazy (() => import ("../../Settings/HowItWorks") );

const AppMain = () => {
  let isLoggedIn = false;

  const { user: currentUser } = useSelector((state) => state.auth);

  if ( currentUser && ( currentUser.roleId === 1 || currentUser.roleId === 2) ) {
    isLoggedIn = true;
  } 
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    if (["/login"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  return (
    <Routes>
      <Route element={<ProtectedRoutes isLoggedIn={isLoggedIn} />}>
        <Route path="dashboard" key="dashboard" element={<Dashboard />} />
        <Route path="profile" key="profile" element={<Profile />} />
        
        <Route path="settings" key="settings">
          <Route path="general" key="settings/general" element={<SettingsGeneral currentUser={currentUser}/>} />
          <Route path="config" key="settings/config" element={<SettingsConfig currentUser={currentUser}/>} />
          <Route path="homeCms" key="settings/homeCms" element={<HomeCms currentUser={currentUser}/>} />
          <Route path="aboutCms" key="settings/aboutCms" element={<AboutCms currentUser={currentUser}/>} />
          <Route path="howItWorks" key="settings/howItWorks" element={<HowItWorks currentUser={currentUser}/>} />
        </Route>
        
        
        
        
        <Route path="user" key="user">
          <Route path="admin" key="admin" element={<AdminList currentUser={currentUser}/>} />
          <Route path="admin/add" key="admin/add" element={<Admin currentUser={currentUser}/>} />
          <Route path="admin/:id" key="admin" element={<Admin currentUser={currentUser}/>} />
          {/* <Route path="users" key="users" element={<CSRList currentUser={currentUser}/>} /> */}
          <Route path="users/add" key="users/add" element={<AddUsers currentUser={currentUser}/>} />
          <Route path="csr/:id" key="csr" element={<AddUsers currentUser={currentUser}/>} />
          <Route path="all" element={<UserList currentUser={currentUser}/>} />
          <Route path=":id" element={<User currentUser={currentUser}/>} />
          <Route path="query" key="query" element={<Query currentUser={currentUser} />}    />
          <Route path="query/:id" key="query" element={<QueryView currentUser={currentUser} />}    />
        </Route>
        
       
      
        
      </Route>
      <Route element={<PublicRoutes isLoggedIn={isLoggedIn} />}>
        <Route path="login" key="login" element={<Login />} />
        <Route
          path="forgot-password"
          key="forgot-password"
          exact
          element={<ForgotPassword />}
        />
        <Route
          path="reset-password"
          key="reset-password"
          element={<ResetPassword />}
        />
        <Route path="/" key="/" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default AppMain;
