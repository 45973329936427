import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../../reducers/ThemeOptions";
import {
  AdminNav, Advisor, CSRNav
} from "./NavItems";
import withRouter from "../../../hooks/withRouter";

function Nav(props) {
  const { enableMobileMenu, setEnableMobileMenu, authDetails } = props;

  const capability = (authDetails?.Role?.capability);

  const [advisorNavItems, setAdvisorNavitems] = useState(Advisor);

  let parseCapability;
  if (capability != undefined) {
    parseCapability = JSON.parse(capability)

  }

  useEffect(() => {


    if (parseCapability?.add == false) {
      const newNavItems = [
        {
          icon: "pe-7s-graph2",
          label: "Dashboard",
          to: "/dashboard",
        },
        {
          icon: "pe-7s-user",
          label: "Client",
          content: [
            {
              label: "List",
              to: "/user/all",
            },
          ],
        },
      ];

      setAdvisorNavitems(newNavItems)

    }



  }, [capability]);
  const toggleMobileSidebar = () => {
    setEnableMobileMenu(!enableMobileMenu);
  };

  return (
    <>
      {/* <h5 className="app-sidebar__heading"></h5> */}
      {authDetails.roleId === 1 ? (
        <MetisMenu
          content={AdminNav}
          onSelected={toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      ) : (
        <MetisMenu
          content={advisorNavItems}
          onSelected={toggleMobileSidebar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));