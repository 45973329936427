export const AdminNav = [
  {
    icon: "pe-7s-graph2",
    label: "Dashboard",
    to: "/dashboard",
  },
  
  {
    icon: "pe-7s-user",
    label: "Advisors",
    content: [
      {
        label: "Add",
        to: "/user/admin/add",
      },
      {
        label: "List",
        to: "/user/admin",
      },
    ],
  },
  {
    icon: "pe-7s-user",
    label: "Client",
    content: [
      {
        label: "Add",
        to: "/user/users/add",
      },
      {
        label: "List",
        to: "/user/all",
      },
    ],
  },

  {
    icon: "pe-7s-config",
    label: "Settings",
    to: "/settings/general",
    content: [
      {
        label: "Manage Roles",
        to: "/settings/general",
      },
      {
        label: "General Settings",
        to: "/settings/config",
      },
      {
        label: "Home CMS",
        to: "/settings/homeCms",
      },
      {
        label: "About CMS",
        to: "/settings/aboutCms",
      },
      {
        label: "How It works CMS",
        to: "/settings/howItWorks",
      },
    ],
  },

  {
    icon: "pe-7s-user",
    label: "Requests",
    to : "/user/query"
  },
  
];

export const Advisor = [
  {
    icon: "pe-7s-graph2",
    label: "Dashboard",
    to: "/dashboard",
  },

 

  {
    icon: "pe-7s-user",
    label: "Client",
    content: [
      {
        label: "Add",
        to: "/user/users/add",
      },
      {
        label: "List",
        to: "/user/all",
      },
    ],
  },
  
];